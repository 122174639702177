import React from "react";
import classes from "../../../App.module.scss";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../models/FormValues";
import FormSection from "../FormSection/FormSection";
import { FormDetails } from "../../../models/FormDetails";
import CheckboxInput from "../../Inputs/CheckboxInput/CheckboxInput";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import ReactMarkdown from "react-markdown";

const MotivationForm = (props: {
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<any>;
  formDetails: FormDetails | null;
  watch: UseFormWatch<FormValues>;
}) => {
  const { t } = useTranslation();
  const showMotivation = props.watch("showMotivation");

  return (
    <FormSection headingTitle={t("form:motivation")}>
      <>
        <ReactMarkdown linkTarget="_blank">
          {t("form:motivationDescription")}
        </ReactMarkdown>
        <CheckboxInput
          formKey={"showMotivation"}
          label={t("form:showMotivationLabel")}
          required={false}
          register={props.register}
        />

        {showMotivation && (
          <div>
            <label style={{ fontWeight: "normal" }}>
              {t("form:motivationLabel")}
            </label>
            <textarea
              placeholder={
                props.formDetails?.hidePlaceholders &&
                props.formDetails.hidePlaceholders["motivation"]
                  ? ""
                  : t("form:motivationPlaceholder")
              }
              {...props.register("motivation", {
                required: props.watch("showMotivation"),
              })}
            />
            {props.errors.motivation && (
              <div className={classes.formError}>
                {t("form:motivationRequired")}
              </div>
            )}
          </div>
        )}
      </>
    </FormSection>
  );
};
export default MotivationForm;
